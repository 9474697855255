import type { DeviceSettings, Setting, UserSettings } from '@/services/types';
import { DiSEqCVersion } from 'varos-connect-shared-ts';
import DeviceInfoVue from '@/components/Settings/DeviceInfo.vue';
import TimeAndDateVue from '@/components/Settings/TimeAndDate.vue';
import ConnectionVue from '@/components/Settings/Connection.vue';

export const CATEGORIES: Setting[] = [
    {
        id: 'connection',
        type: 'category',
        name: 'connection',
        icon: 'wifi',
        children: [
            {
                type: 'component',
                component: ConnectionVue
            }
        ]
    },
    {
        id: 'measurement',
        type: 'category',
        name: 'measurement',
        icon: 'straighten',
        children: [
            {
                kind: 'user',
                id: 'skip_expert_settings',
                type: 'checkbox',
                name: 'skip_expert_settings',
                description: 'skip_expert_settings'
            },
            {
                kind: 'user',
                id: 'skip_band_change_confirmation',
                type: 'checkbox',
                name: 'skip_band_change_confirmation',
                description: 'skip_band_change_confirmation'
            },
            {
                kind: 'user',
                id: 'skip_try_with_docsis_modem_confirmation',
                type: 'checkbox',
                name: 'skip_try_with_docsis_modem_confirmation',
                description: 'skip_try_with_docsis_modem_confirmation'
            },
            {
                type: 'group',
                name: 'oscillator_frequencies',
                children: [
                    {
                        kind: 'user',
                        id: 'oscillator/ku_low',
                        type: 'number',
                        name: 'ku_low',
                        description: 'ku_low',
                        unit: 'GHz'
                    },
                    {
                        kind: 'user',
                        id: 'oscillator/ku_high',
                        type: 'number',
                        name: 'ku_high',
                        description: 'ku_high',
                        unit: 'GHz'
                    },
                    {
                        kind: 'user',
                        id: 'oscillator/ku_wideband',
                        type: 'number',
                        name: 'ku_wideband',
                        description: 'ku_wideband',
                        unit: 'GHz'
                    }
                ]
            },
            {
                kind: 'user',
                id: 'level_unit',
                type: 'select',
                name: 'level_unit',
                doNotTranslateItems: true,
                items: [
                    'dBµV',
                    'dBm'
                ]
            },
            {
                kind: 'user',
                id: 'ber_limit_exponent',
                type: 'select',
                name: 'ber_limit',
                description: 'ber_limit',
                doNotTranslateItems: true,
                items: [
                    { text: '< 1.00e-8', value: 8 },
                    { text: '< 1.00e-9', value: 9 }
                ]
            }
            // TODO: add setting to customize scan configs
        ]
    },
    {
        id: 'general',
        type: 'category',
        name: 'general',
        icon: 'settings',
        children: [
            {
                kind: 'user',
                id: 'language',
                type: 'select',
                name: 'language',
                doNotTranslateItems: true,
                items: [
                    { text: 'Deutsch', value: 'de-DE' },
                    { text: 'English', value: 'en-US' }
                ]
            },
            {
                kind: 'user',
                id: 'decimal_separator',
                type: 'select',
                name: 'decimal_separator',
                description: 'decimal_separator',
                items: [
                    { text: 'settings.decimal_point', value: '.' },
                    { text: 'settings.decimal_comma', value: ',' }
                ]
            },
            {
                kind: 'user',
                id: 'skip_tutorial',
                type: 'checkbox',
                name: 'skip_tutorial'
            }
            // TODO: add setting to customize start page
        ]
    },
    {
        id: 'appearance',
        type: 'category',
        name: 'appearance',
        icon: 'visibility',
        children: [
            // {
            //     kind: 'user',
            //     id: 'theme',
            //     type: 'select',
            //     name: 'theme',
            //     items: [
            //         { value: 'system_settings', text: 'settings.system_settings' },
            //         { value: 'light', text: 'settings.light' },
            //         { value: 'dark', text: 'settings.dark' }
            //     ]
            // },
            {
                kind: 'user',
                id: 'show_battery_status_percent',
                type: 'checkbox',
                name: 'show_battery_status_percent'
            },
            {
                kind: 'user',
                id: 'show_help_icons',
                type: 'checkbox',
                name: 'show_help_icons'
            },
            {
                kind: 'user',
                id: 'show_colored_values',
                type: 'checkbox',
                name: 'show_colored_values',
                description: 'show_colored_values'
            }
        ]
    },
    {
        id: 'device',
        type: 'category',
        name: 'device',
        icon: 'memory',
        children: [
            {
                type: 'component',
                component: DeviceInfoVue
            },
            {
                type: 'component',
                component: TimeAndDateVue
            },
            {
                type: 'group',
                name: 'docsis_modem',
                children: [
                    {
                        kind: 'device',
                        id: 'docsis_modem_boot_on_startup',
                        type: 'checkbox',
                        name: 'docsis_modem_boot_on_startup',
                        description: 'docsis_modem_boot_on_startup'
                    },
                    {
                        kind: 'device',
                        id: 'docsis_modem_turn_off_timeout_battery',
                        type: 'select',
                        name: 'docsis_modem_turn_off_timeout_battery',
                        description: 'docsis_modem_turn_off_timeout_battery',
                        items: [
                            { value: 0, text: 'never' },
                            { value: 60, text: 'after_1_min' },
                            { value: 120, text: 'after_2_min' },
                            { value: 300, text: 'after_5_min' },
                            { value: 600, text: 'after_10_min' },
                            { value: 900, text: 'after_15_min' },
                            { value: 1200, text: 'after_20_min' },
                            { value: 1800, text: 'after_30_min' }
                        ]
                    },
                    {
                        kind: 'device',
                        id: 'docsis_modem_turn_off_timeout_plugged_in',
                        type: 'select',
                        name: 'docsis_modem_turn_off_timeout_plugged_in',
                        description: 'docsis_modem_turn_off_timeout_plugged_in',
                        items: [
                            { value: 0, text: 'never' },
                            { value: 60, text: 'after_1_min' },
                            { value: 120, text: 'after_2_min' },
                            { value: 300, text: 'after_5_min' },
                            { value: 600, text: 'after_10_min' },
                            { value: 900, text: 'after_15_min' },
                            { value: 1200, text: 'after_20_min' },
                            { value: 1800, text: 'after_30_min' }
                        ]
                    }
                ]
            }
            // TODO: Add setting "Factory reset"
            // TODO: Add licensing setting
        ]
    },
    {
        type: 'link',
        name: 'datalogs',
        to: '/datalogs',
        icon: 'assessment'
    },
    {
        type: 'link',
        name: 'measurement_plans',
        to: '/plans',
        icon: 'assignment'
    }
];

export const DEFAULT_USER_SETTINGS: UserSettings = {
    skip_expert_settings: false,
    skip_band_change_confirmation: false,
    skip_try_with_docsis_modem_confirmation: false,
    'oscillator/ku_low': 9.750,
    'oscillator/ku_high': 10.600,
    'oscillator/ku_wideband': 10.410,
    level_unit: 'dBµV',
    ber_limit_exponent: 8,
    language: 'de-DE',
    decimal_separator: '.',
    skip_tutorial: false,
    theme: 'system_settings',
    show_battery_status_percent: false,
    show_help_icons: true,
    show_colored_values: true,
    last_lnb_settings: {
        [DiSEqCVersion.Off]: { satellite: undefined, diseqc_parameters: undefined },
        [DiSEqCVersion.DiSEqC_v1_0]: { satellite: 1, diseqc_parameters: undefined },
        [DiSEqCVersion.DiSEqC_v1_1]: { satellite: 1, diseqc_parameters: { repeats: 1, uncommitted_switches: 0x00 } },
        [DiSEqCVersion.DiSEqC_v1_2]: { satellite: 1, diseqc_parameters: undefined },
        [DiSEqCVersion.DiSEqC_v2_0]: { satellite: 1, diseqc_parameters: undefined },
        [DiSEqCVersion.JESS]: { satellite: 1, diseqc_parameters: { userband: 0, freq: 0, delay: 0 } },
        [DiSEqCVersion.UNICABLE]: { satellite: 1, diseqc_parameters: { userband: 0, freq: 0, delay: 0 } }
    },
    last_measured_frequencies: {},
    last_selected_frequency_type: {}
};

export const DEFAULT_DEVICE_SETTINGS: DeviceSettings = {
    docsis_modem_boot_on_startup: false,
    docsis_modem_turn_off_timeout_battery: 120,
    docsis_modem_turn_off_timeout_plugged_in: 600
};
