import QueryHelper from '@/utils/QueryHelper';
import { defineStore } from 'pinia';
import type { MeasurementOptions } from '@/services/types';
import { TransmissionMethod } from 'varos-connect-shared-ts';
import { Route } from 'vue-router';
import { ref, watch } from 'vue';
import { useDeviceStore } from './device';

export const useRouteStore = defineStore('route', () => {
    const transmissionMethod = ref(null as TransmissionMethod|null);
    const measurementOptions = ref(null as MeasurementOptions|null);
    const stationId = ref(null as null|number);
    const audioPID = ref(null as null|number);
    const planId = ref(null as null|string);

    function switchRoute (route: Route) {
        transmissionMethod.value = route.params.transmissionMethod as TransmissionMethod|undefined ?? null;

        const { planId: pId, ...partialOptions } = QueryHelper.measurementOptionsFromQuery(route.query);

        planId.value = pId ?? null;

        if ('frequency' in route.params) {
            const frequency = Number.parseFloat(route.params.frequency ?? '');
            if (Number.isNaN(frequency)) return null;

            const options: MeasurementOptions = {
                frequency: frequency * 1000, // MHz (query) to kHz (options)
                ...partialOptions
            };
            measurementOptions.value = options;
        } else {
            measurementOptions.value = null;
        }

        const sId = Number.parseInt(route.params.stationId ?? '');
        stationId.value = Number.isNaN(sId) ? null : sId;

        const aPID = Number.parseInt(route.params.audioPID ?? '');
        audioPID.value = Number.isNaN(aPID) ? null : aPID;
    }

    const deviceStore = useDeviceStore();
    watch([measurementOptions, transmissionMethod], () => {
        if (transmissionMethod.value === null) return;
        if (measurementOptions.value === null) return;

        const {
            frequency,
            mod_standard,
            mod_scheme,
            bandwidth,
            symbol_rate,
            plp_id,
            afc,
            with_docsis_modem,
            diplexer_high_split
        } = measurementOptions.value;

        deviceStore.lock({
            transmission_method: transmissionMethod.value,
            frequency,
            mod_standard: mod_standard === undefined ? undefined : [mod_standard],
            mod_scheme: mod_scheme === undefined ? undefined : [mod_scheme],
            bandwidth: bandwidth === undefined ? undefined : [bandwidth],
            symbol_rate: symbol_rate === undefined ? undefined : [symbol_rate],
            plp_id,
            afc,
            with_docsis_modem,
            diplexer_high_split
        });
    });

    return {
        transmissionMethod,
        measurementOptions,
        stationId,
        planId,
        audioPID,

        switchRoute
    };
});
