
import { createStoredWifiNetwork } from '@/services';
import { computed, defineComponent, ref, watch } from 'vue';
import type { StoredNetwork } from 'varos-connect-shared-ts';
import PasswordInputVue from './PasswordInput.vue';
import IPInputVue from './IPInput.vue';

import { useNetMaskRules, useRules } from '@/composables/useValidationRules';
import i18n from '@/plugins/i18n';
import { useSnackbarStore } from '@/stores';

export default defineComponent({
    components: {
        PasswordInput: PasswordInputVue,
        IPInput: IPInputVue
    },
    props: {
        value: {
            type: Boolean,
            required: true
        }
    },
    setup (props, { emit }) {
        const visible = computed<boolean>({
            get () { return props.value; },
            set (val) { emit('input', val); }
        });

        const password = ref('');
        const dhcp = ref(true);
        const encrypted = ref(true);
        const ssid = ref('');
        const mac = ref('');
        const ipv4 = ref('');
        const mask = ref('');
        const gateway = ref('');
        const dns1 = ref('');
        const dns2 = ref('');

        const loading = ref(false);

        // reset values, when model or visibility changes
        watch([visible], () => {
            loading.value = false;
            password.value = '';
            ssid.value = '';
            mac.value = '';
            dhcp.value = true;
            encrypted.value = false;
            ipv4.value = '';
            mask.value = '';
            gateway.value = '';
            dns1.value = '';
            dns2.value = '';
        });

        async function createStoredNetwork () {
            let static_config: StoredNetwork['static_config'];

            if (dhcp.value) {
                static_config = undefined;
            } else {
                static_config = {
                    ipv4: ipv4.value,
                    mask: mask.value,
                    gateway: gateway.value || undefined,
                    dns1: dns1.value || undefined,
                    dns2: dns2.value || undefined
                };
            }

            const network = {
                ssid: ssid.value || undefined,
                mac: mac.value || undefined,
                passphrase: encrypted.value ? password.value : undefined,
                static_config
            } as StoredNetwork;

            const newNetwork = await createStoredWifiNetwork(network);

            emit('network_created', newNetwork);
        }

        async function submit () {
            loading.value = true;

            try {
                await createStoredNetwork();

                visible.value = false;
            } catch (err) {
                useSnackbarStore().show(
                    i18n.t('settings.wifi_prompts.failed_to_create_network').toString(),
                    { color: 'error' }
                );
            } finally {
                loading.value = false;
            }
        }

        const macRules = useRules({
            pattern: /^(?:[a-fA-F0-9]{2}([-:]))(?:[a-fA-F0-9]{2}\1){4}[a-fA-F0-9]{2}$/
        });
        const maskRules = useNetMaskRules();
        const ssidRules = useRules({
            maxLength: 32
        });

        const noDHCPValid = ref(false);
        const baseValid = ref(false);

        return {
            visible,
            encrypted,

            password,
            dhcp,
            ssid,
            mac,
            ipv4,
            mask,
            gateway,
            dns1,
            dns2,

            macRules,
            maskRules,
            ssidRules,
            noDHCPValid,
            baseValid,

            submit,

            loading
        };
    }
});
