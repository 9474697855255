
import { useNetMaskRules } from '@/composables/useValidationRules';
import i18n from '@/plugins/i18n';
import { deleteStoredWifiNetwork, updateStoredWifiNetwork } from '@/services';
import { useSnackbarStore } from '@/stores';
import { computed, defineComponent, ref, watch, type PropType } from 'vue';
import type { AvailableNetwork, StoredNetwork as APIStoredNetwork } from 'varos-connect-shared-ts';
import IPInputVue from './IPInput.vue';

type StoredNetwork = Omit<APIStoredNetwork, 'passphrase'> & { id: string };

export default defineComponent({
    components: {
        IPInput: IPInputVue
    },
    props: {
        storedNetwork: {
            type: Object as PropType<StoredNetwork>
        },
        availableNetwork: {
            type: Object as PropType<AvailableNetwork>
        },
        value: {
            type: Boolean,
            required: true
        },
        isActiveNetwork: {
            type: Boolean,
            default: false
        }
    },
    setup (props, { emit }) {
        const visible = computed<boolean>({
            get () { return props.value; },
            set (val) { emit('input', val); }
        });

        const dhcp = ref(true);
        const ipv4 = ref('');
        const mask = ref('');
        const gateway = ref('');
        const dns1 = ref('');
        const dns2 = ref('');

        const submitting = ref(false);
        const deleting = ref(false);
        const loading = computed(() => deleting.value || submitting.value);
        watch(visible, () => {
            submitting.value = false;
            deleting.value = false;
        });

        // reset values, when model or visibility changes
        watch([visible, () => props.storedNetwork], () => {
            const storedNetwork = props.storedNetwork;

            dhcp.value = true;
            ipv4.value = '';
            mask.value = '';
            gateway.value = '';
            dns1.value = '';
            dns2.value = '';

            if (storedNetwork === undefined) return;

            dhcp.value = storedNetwork.static_config === undefined;
            if (storedNetwork.static_config !== undefined) {
                ipv4.value = storedNetwork.static_config.ipv4;
                mask.value = storedNetwork.static_config.mask;
                gateway.value = storedNetwork.static_config.gateway ?? '';
                dns1.value = storedNetwork.static_config.dns1 ?? '';
                dns2.value = storedNetwork.static_config.dns2 ?? '';
            }
        });

        async function updateStoredNetwork () {
            if (props.storedNetwork === undefined) return;
            await new Promise(resolve => setTimeout(resolve, 1000));

            let static_config: StoredNetwork['static_config'];

            if (dhcp.value) {
                static_config = undefined;
            } else {
                static_config = {
                    ipv4: ipv4.value,
                    mask: mask.value,
                    gateway: gateway.value || undefined,
                    dns1: dns1.value || undefined,
                    dns2: dns2.value || undefined
                };
            }

            await updateStoredWifiNetwork(props.storedNetwork.id, {
                ssid: props.storedNetwork.ssid,
                mac: props.storedNetwork.mac,
                static_config
            } as APIStoredNetwork);

            const newStoredNetwork: StoredNetwork = {
                ...props.storedNetwork,
                static_config
            };

            emit('network_updated', newStoredNetwork);
        }

        async function deleteStoredNetwork () {
            if (props.storedNetwork === undefined) return;

            deleting.value = true;

            try {
                deleteStoredWifiNetwork(props.storedNetwork.id);
                visible.value = false;

                emit('network_deleted');
            } catch (err) {
                console.error(err);
                useSnackbarStore().show(
                    i18n.t('settings.wifi_prompts.failed_to_delete_network').toString(),
                    { color: 'error' }
                );
            } finally {
                submitting.value = false;
            }
        }

        async function submit () {
            submitting.value = true;

            try {
                await updateStoredNetwork();

                visible.value = false;
            } catch (err) {
                useSnackbarStore().show(
                    i18n.t('settings.wifi_prompts.failed_to_update_network').toString(),
                    { color: 'error' }
                );
            } finally {
                submitting.value = false;
            }
        }

        const maskRules = useNetMaskRules();

        const noDHCPValid = ref(false);

        return {
            visible,

            dhcp,
            ipv4,
            mask,
            gateway,
            dns1,
            dns2,

            maskRules,

            submit,
            deleteStoredNetwork,

            deleting,
            submitting,
            loading,
            noDHCPValid
        };
    }
});
