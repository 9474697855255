
import { useWifiNetworks } from '@/composables/useWifiNetworks';
import i18n from '@/plugins/i18n';
import { ResponseError } from '@/services/ResponseError';
import { useHelpStore, useSnackbarStore } from '@/stores';
import { defineComponent, ref, watch } from 'vue';
import { type AvailableNetwork, Cipher, AuthenticationMethod, StoredNetwork as StoredNetworkAPI } from 'varos-connect-shared-ts';

import NetworkVue from './Connection/Network.vue';
import GroupSettingVue from './GroupSetting.vue';
import PasswordInputVue from './Connection/PasswordInput.vue';
import NetworkInfoDialogVue from './Connection/NetworkInfoDialog.vue';
import AddStoredNetworkDialogVue from './Connection/AddStoredNetworkDialog.vue';
import AbstractSettingVue from './AbstractSetting.vue';

const SUPPORTED_CIPHERS: Set<Cipher> = new Set([Cipher.WEP, Cipher.WPA, Cipher.WPA2]);

const SUPPORTED_AUTHENTICATION_METHODS: Set<AuthenticationMethod> = new Set([AuthenticationMethod.PSK]);

type StoredNetwork = StoredNetworkAPI & { id: string };

export default defineComponent({
    components: {
        GroupSetting: GroupSettingVue,
        Network: NetworkVue,
        NetworkInfoDialog: NetworkInfoDialogVue,
        AddStoredNetworkDialog: AddStoredNetworkDialogVue,
        PasswordInput: PasswordInputVue,
        AbstractSetting: AbstractSettingVue
    },
    setup () {
        const {
            activeNetwork,
            knownNetworks,
            unknownNetworks,
            storedNetworks,
            loading,
            connect,
            allStoredNetworks,
            isHotspotEnabled
        } = useWifiNetworks();

        const connectingNetwork = ref<AvailableNetwork>();

        interface PromptOptions {
            password?: true,
            resolve?(): void,
            reject?(): void,
            onlyClose?: boolean,
            text?: string,
            heading?: string,
            helpEntry?: string
        }
        const promptOptions = ref<PromptOptions>({});

        const promptVisible = ref(false);
        const password = ref('');
        const passwordValid = ref(false);

        // set prompt visible to true, if prompt options change
        watch(promptOptions, (val) => {
            if (val === undefined) return;
            promptVisible.value = true;
        });

        async function connectToNetwork (network: AvailableNetwork, passphrase?: string) {
            connectingNetwork.value = network;

            const controller = new AbortController();
            // we wait 5000ms max
            setTimeout(() => controller.abort(), 5000);

            try {
                await connect({ mac: network.mac }, passphrase, controller.signal);
            } catch (err) {
                if (!(err instanceof Error)) throw err;

                if (err.name === 'AbortError') {
                    window.location.reload();
                    return;
                }

                if (err instanceof ResponseError || err.name === 'ResponseError') {
                    useSnackbarStore().show(
                        i18n.t('settings.wifi_prompts.failed_to_connect').toString(),
                        { color: 'error' }
                    );
                    return;
                }

                throw err;
            } finally {
                connectingNetwork.value = undefined;
            }
        }

        const info = ref<{ visible: boolean, stored?: StoredNetwork, available?: AvailableNetwork }>({ visible: false });

        function onNetworkClick (network: AvailableNetwork) {
            if (!network.encrypted) {
                new Promise<void>((resolve) => {
                    promptOptions.value = { resolve, text: i18n.t('settings.wifi_prompts.confirm_connect', { name: network.ssid ?? network.mac }).toString() };
                }).then(() => connectToNetwork(network));
                return;
            }

            if (network.ciphers.find((x) => SUPPORTED_CIPHERS.has(x)) === undefined) {
                promptOptions.value = { onlyClose: true, heading: 'settings.wifi_prompts.connection_not_possible', text: i18n.t('settings.wifi_prompts.unsupported_ciphers').toString(), helpEntry: 'wifi#supported-ciphers' };
                return;
            }
            if (network.auth_methods.find((x) => SUPPORTED_AUTHENTICATION_METHODS.has(x)) === undefined) {
                promptOptions.value = { onlyClose: true, heading: 'settings.wifi_prompts.connection_not_possible', text: i18n.t('settings.wifi_prompts.unsupported_authentication_methods').toString(), helpEntry: 'wifi#supported-authentication-methods' };
                return;
            }

            // network is encrypted, but has supported cipher and authentication method

            new Promise<void>((resolve) => {
                promptOptions.value = { resolve, text: i18n.t('settings.wifi_prompts.confirm_connect', { name: network.ssid ?? network.mac }).toString() };
            }).then(() => {
                if (network.stored_id !== undefined) return undefined;

                return new Promise<string>((resolve, reject) => {
                    password.value = '';
                    promptOptions.value = { password: true, resolve: () => resolve(password.value), reject };
                });
            }).then((passphrase?: string) => connectToNetwork(network, passphrase));
        }

        function updateStoredNetwork (network: StoredNetwork) {
            allStoredNetworks.value = allStoredNetworks.value.map(x => x.id !== network.id ? x : network);

            if (info.value.available && info.value.available === activeNetwork.value?.available) {
                // when the active network changes, we want to reconnect to make
                // sure the new settings are applied properly
                connectToNetwork(info.value.available);
            }
        }

        function removeStoredNetwork () {
            if (!info.value.stored) return;
            const id = info.value.stored.id;

            allStoredNetworks.value = allStoredNetworks.value.filter(x => x.id !== id);

            // remove stored_id from corresponding available network
            if (info.value.available) info.value.available.stored_id = undefined;
        }

        function addStoredNetwork (network: StoredNetwork) {
            allStoredNetworks.value.push(network);
        }

        const addStoredNetworkVisible = ref(false);
        const helpStore = useHelpStore();

        return {
            onNetworkClick,
            connectingNetwork,

            activeNetwork,
            knownNetworks,
            unknownNetworks,
            storedNetworks,
            allStoredNetworks,
            loading,
            isHotspotEnabled,

            promptOptions,
            promptVisible,
            helpStore,
            password,
            passwordValid,

            info,
            addStoredNetworkVisible,

            updateStoredNetwork,
            removeStoredNetwork,
            addStoredNetwork
        };
    }
});
