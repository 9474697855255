import { defineStore } from 'pinia';
import type { Plan, PlanItem } from '@/services/types';
import STD_PLAN_BG from '@/assets/standard_plans/bg.json';
import STD_PLAN_DIGITAL from '@/assets/standard_plans/digital.json';
import STD_PLAN_DAB from '@/assets/standard_plans/dab.json';
import { TransmissionMethod } from 'varos-connect-shared-ts';
import { computed, ref } from 'vue';
import { computedWithControl } from '@vueuse/core';
import { getFromUserStorage, saveToUserStorage } from '@/services';
import { useRouteStore } from './route';

const STANDARD_PLANS = {
    tv_digital: {
        name: 'Digital',
        items: STD_PLAN_DIGITAL,
        transmissionMethods: [TransmissionMethod.Cable, TransmissionMethod.Antenna]
    },
    tv_bg: {
        name: 'BG',
        items: STD_PLAN_BG,
        transmissionMethods: [TransmissionMethod.Cable, TransmissionMethod.Antenna]
    },
    radio_std: {
        name: 'Standard',
        items: STD_PLAN_DAB,
        transmissionMethods: [TransmissionMethod.Radio]
    }
};

const sortedMethods = [TransmissionMethod.Cable, TransmissionMethod.Antenna, TransmissionMethod.Satellite, TransmissionMethod.Radio];

const FILE_PATH = 'measurement_plans.json';

function planComparator (a: Plan, b: Plan): number {
    return a.name.localeCompare(b.name) ||
            sortedMethods.indexOf(a.transmission_method) - sortedMethods.indexOf(b.transmission_method);
}

export const usePlanStore = defineStore('plan', () => {
    const plans = ref<Plan[]>([]);
    const loadingPlans = ref(true);

    async function loadPlans () {
        loadingPlans.value = true;

        try {
            const unsortedPlans = await getFromUserStorage<Plan[]>(FILE_PATH) ?? [];

            plans.value = unsortedPlans.sort(planComparator);
        } catch (err) {
            // TODO: Handle Error
        } finally {
            loadingPlans.value = false;
        }
    }

    loadPlans();

    const routeStore = useRouteStore();
    const plan = computedWithControl<Plan|undefined, unknown>(
        [plans, () => routeStore.planId, () => routeStore.transmissionMethod],
        () => plans.value.find(x => x.id === routeStore.planId && x.transmission_method === routeStore.transmissionMethod)
    );

    const items = computedWithControl<PlanItem[], unknown>([plan, () => routeStore.transmissionMethod], () => {
        if (plan.value !== undefined) return plan.value.items;

        switch (routeStore.transmissionMethod) {
        case TransmissionMethod.Cable:
            return STANDARD_PLANS.tv_digital.items;

        case TransmissionMethod.Antenna:
            return STANDARD_PLANS.tv_digital.items;

        case TransmissionMethod.Radio:
            return STANDARD_PLANS.radio_std.items;
        }

        return [];
    });

    function standardPlansByTransmissionMethod (transmissionMethod: TransmissionMethod): Pick<Plan, 'id'|'name'|'items'>[] {
        return Object.entries(STANDARD_PLANS)
            .filter(([, { transmissionMethods }]) => transmissionMethods.includes(transmissionMethod))
            .map(([id, { name, items }]) => ({ id, name, items }));
    }

    const visiblePlans = computed<Plan[]>(() => plans.value.filter(
        p => p.visible && (routeStore.transmissionMethod === null || routeStore.transmissionMethod === p.transmission_method)
    ).sort((a, b) => {
        if (a.favorite && !b.favorite) return -1;
        if (!a.favorite && b.favorite) return 1;
        return planComparator(a, b);
    }));

    const plansByTransmissionMethod = computed(() => (method: TransmissionMethod): Plan[] =>
        plans.value.filter(({ transmission_method }) => transmission_method === method)
    );

    function generateID (): string {
        const array = new Uint8Array(6);
        self.crypto.getRandomValues(array);
        return btoa(String.fromCharCode(...array)).replace(/\+/g, '-').replace(/\//g, '_');
    }

    async function syncPlans (newPlans?: Plan[]) {
        newPlans = (newPlans ?? plans.value).sort(planComparator);

        await saveToUserStorage<Plan[]>(FILE_PATH, newPlans);
        plans.value = newPlans;
    }

    async function deletePlan (id: string): Promise<void> {
        const newPlans = plans.value.filter(p => id !== p.id);
        return syncPlans(newPlans);
    }

    async function updatePlan (id: string, plan: Omit<Plan, 'id'>): Promise<void> {
        const newPlans = plans.value.map(x => x.id === id ? { id, ...plan } : x);
        return syncPlans(newPlans);
    }

    async function createPlan (plan: Omit<Plan, 'id'>): Promise<Plan> {
        const newPlan = { id: generateID(), ...plan };

        await syncPlans([newPlan, ...plans.value]);

        return newPlan;
    }

    async function duplicatePlan (id: string, name: string): Promise<Plan> {
        const planToDuplicate = plans.value.find(x => x.id === id);

        if (planToDuplicate === undefined) throw new Error(`Couldn't find plan with id ${id}`);

        const newPlan: Plan = { ...structuredClone(planToDuplicate), id: generateID(), name };

        await syncPlans([newPlan, ...plans.value]);

        return newPlan;
    }

    return {
        plan,
        items,
        plans,
        visiblePlans,
        loadingPlans,
        loadPlans,
        standardPlansByTransmissionMethod,
        plansByTransmissionMethod,

        deletePlan,
        updatePlan,
        createPlan,
        duplicatePlan,
        syncPlans
    };
});
