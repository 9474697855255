import { getAvailableWifiNetworks, getStoredWifiNetworks, getWiFiSettings, connectToWifiNetwork } from '@/services';
import { computed, ref, watch } from 'vue';
import { type WifiSettings, type AvailableNetwork, type StoredNetwork as APIStoredNetwork } from 'varos-connect-shared-ts';
import type { RequireAtLeastOne } from 'varos-connect-shared-ts/UtilityTypes';

function isSameNetwork (
    a: Partial<Pick<AvailableNetwork, 'ssid' | 'mac'>>,
    b: Partial<Pick<AvailableNetwork, 'ssid' | 'mac'>>
): boolean {
    if (a.mac !== undefined && a.mac === b.mac) return true;
    if (a.ssid !== undefined && a.ssid === b.ssid) return true;

    return false;
}

type StoredNetwork = Omit<APIStoredNetwork, 'passphrase'> & { id: string };

export function useWifiNetworks () {
    const wifiSettings = ref<WifiSettings>();
    async function fetchSettings () {
        wifiSettings.value = await getWiFiSettings();
    }

    const allAvailableNetworks = ref<AvailableNetwork[]>([]);
    async function fetchAvailableNetworks () {
        allAvailableNetworks.value = await getAvailableWifiNetworks();
    }

    const allStoredNetworks = ref<StoredNetwork[]>([]);
    async function fetchStoredNetworks () {
        allStoredNetworks.value = await getStoredWifiNetworks();
    }

    type KnownNetwork = { available: AvailableNetwork, stored: StoredNetwork };

    const activeNetwork = ref<KnownNetwork>();
    const knownNetworks = ref<KnownNetwork[]>([]);
    const unknownNetworks = ref<AvailableNetwork[]>([]);
    const storedNetworks = ref<StoredNetwork[]>([]);

    const isHotspotEnabled = computed(() => wifiSettings.value?.mode === 'ap');

    watch([wifiSettings, allAvailableNetworks, allStoredNetworks], () => {
        let active: KnownNetwork|undefined;
        const known: KnownNetwork[] = [];
        const unknown: AvailableNetwork[] = [];
        const stored: StoredNetwork[] = [...allStoredNetworks.value];

        const activeMAC = wifiSettings.value?.connected ? wifiSettings.value.mac : '';

        for (const network of allAvailableNetworks.value) {
            // find (and potentially remove) from list of stored networks
            let storedNet: StoredNetwork|undefined;
            const index = stored.findIndex(snet => isSameNetwork(network, snet));
            if (index > -1) {
                [storedNet] = stored.splice(index, 1);
            }

            if (network.mac === activeMAC) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                active = { available: network, stored: storedNet! };
            } else if (storedNet !== undefined) {
                known.push({ available: network, stored: storedNet });
            } else {
                unknown.push(network);
            }
        }

        activeNetwork.value = active;
        knownNetworks.value = known.sort((a, b) => b.available.signal_strength - a.available.signal_strength);
        unknownNetworks.value = unknown.sort((a, b) => b.signal_strength - a.signal_strength);
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        storedNetworks.value = stored.sort((a, b) => (a.ssid ?? a.mac)!.localeCompare((b.ssid ?? b.mac)!));
    }, { deep: true });

    const loading = ref(true);

    Promise.all([
        fetchAvailableNetworks(),
        fetchSettings(),
        fetchStoredNetworks()
    ]).then(() => { loading.value = false; });

    async function connect (options: RequireAtLeastOne<{ ssid: string, mac: string }>, passphrase?: string, signal?: AbortSignal) {
        wifiSettings.value = await connectToWifiNetwork(options, passphrase, signal);

        if (!wifiSettings.value?.connected) return;
        if (wifiSettings.value.stored_id === undefined) return;

        const id = wifiSettings.value.stored_id;
        if (allStoredNetworks.value.find(x => x.id === id)) return;

        const newStoredNetwork: StoredNetwork = {
            id,
            mac: wifiSettings.value.mac,
            ssid: wifiSettings.value.ssid
        };

        // add new stored network
        allStoredNetworks.value.push(newStoredNetwork);

        // update stored_id of corresponding available network
        allAvailableNetworks.value = allAvailableNetworks.value.map(x => isSameNetwork(x, options) ? { ...x, stored_id: id } : x);
    }

    return {
        activeNetwork,
        knownNetworks,
        unknownNetworks,
        storedNetworks,
        isHotspotEnabled,
        loading,
        connect,
        allStoredNetworks
    };
}
