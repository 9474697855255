import { computed, ref, watch, type Ref } from 'vue';
import { useSettingsStore } from '@/stores';
import type { DeviceSettings, OscillatorSettings, UserSettings } from '@/services/types';
import { computedEager, computedWithControl } from '@vueuse/core';

export function useUserSetting<T extends keyof UserSettings> (key: T): Ref<UserSettings[T]> {
    const settingsStore = useSettingsStore();

    const returnedRef = ref(structuredClone(settingsStore.getUserSetting(key))) as Ref<UserSettings[T]>;

    function setupWatcher () {
        return watch(
            returnedRef,
            val => settingsStore.setUserSetting(key, val),
            { deep: true }
        );
    }

    let stopWatcher = setupWatcher();

    watch(() => settingsStore.userSettings[key], (val) => {
        stopWatcher();
        returnedRef.value = structuredClone(val);
        stopWatcher = setupWatcher();
    }, { deep: true });

    return returnedRef;
}

export function useUserSettingDirty<T extends keyof UserSettings> (key: T): Readonly<Ref<boolean>> {
    const settingsStore = useSettingsStore();

    return computedEager<boolean>(() => settingsStore.dirtyUserSettings.includes(key));
}

export function useDeviceSettingDirty<T extends keyof DeviceSettings> (key: T): Readonly<Ref<boolean>> {
    const settingsStore = useSettingsStore();

    return computedEager<boolean>(() => settingsStore.dirtyDeviceSettings.includes(key));
}

export function useDeviceSetting<T extends keyof DeviceSettings> (key: T): Ref<DeviceSettings[T]|undefined> {
    const settingsStore = useSettingsStore();

    const returnedRef = ref(settingsStore.deviceSettings?.[key] ? structuredClone(settingsStore.deviceSettings[key]) : undefined) as Ref<DeviceSettings[T]|undefined>;

    function setupWatcher () {
        return watch(
            returnedRef,
            val => settingsStore.setDeviceSetting(key, val),
            { deep: true }
        );
    }

    let stopWatcher = setupWatcher();

    const storeRef = computed<DeviceSettings[T]|undefined>(() => settingsStore.deviceSettings?.[key]);
    watch(storeRef, (val) => {
        stopWatcher();
        returnedRef.value = structuredClone(val);
        stopWatcher = setupWatcher();
    }, { deep: true });

    return returnedRef;
}

export function useOscillatorSettings () {
    const kuLow = useUserSetting('oscillator/ku_low');
    const kuHigh = useUserSetting('oscillator/ku_high');
    const kuWideband = useUserSetting('oscillator/ku_wideband');

    return computedWithControl<OscillatorSettings, unknown>([kuLow, kuHigh, kuWideband], () => ({
        kuLow: kuLow.value,
        kuHigh: kuHigh.value,
        kuWideband: kuWideband.value
    }));
}
