import { Ref, computed, nextTick, ref } from 'vue';

interface LoopRun {
    status: 'running'|'aborted';
    controller: AbortController;
    id: symbol;
}

/**
 * Composable to generate loop that is abortable. This can also handle starting a new loop, while another is still aborting.
 *
 * @param setupVariables Callback to initialize variables at the start of the loop. These variables are passed to the other callbacks
 * @param callback Main callback, which is called on every iteration of the loop. Returning `true` in this callback, stops the loop.
 * @param cleanup Callback at the end of a loop
 */
export function useAbortableLoop<V> (
    setupVariables: () => V,
    callback: (signal: AbortSignal, variables: V) => Promise<boolean | void> | boolean | void,
    cleanup?: (otherLoops: LoopRun[], variables: V) => void
): { running: Ref<boolean>, start: () => void, stop: () => void } {
    const loops = ref<LoopRun[]>([]);

    const running = computed(() => loops.value.some(({ status }) => status === 'running'));

    async function loop () {
        // do not start, if there is any other loop with status "running"
        if (running.value) return;

        const loopID = Symbol('table_loop');
        const controller = new AbortController();
        loops.value.push({ status: 'running', controller, id: loopID });

        controller.signal.addEventListener('abort', () => {
            loops.value = loops.value.map(x => x.id !== loopID ? x : { ...x, status: 'aborted' });
        });

        const variables = setupVariables?.();

        try {
            while (true) {
                if (controller.signal.aborted) return;

                const shouldStop = await callback(controller.signal, variables);

                if (shouldStop) return;
            }
        } finally {
            // delete loop right before exiting
            loops.value = loops.value.filter(({ id }) => id !== loopID);

            cleanup?.(loops.value, variables);
        }
    }

    function stop () {
        for (const { controller } of loops.value.values()) {
            controller.abort();
        }
    }

    function start () {
        stop();

        nextTick(loop);
    }

    return {
        running,
        start,
        stop
    };
}
